import { FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { Controller, type ControllerRenderProps, type FieldValues } from 'react-hook-form';
import type { FC } from 'react';

export interface CheckboxGroupControlledWrapperProps {
  name: string;
  control: any;
  options: Array<{
    label: string;
    value: string;
  }>;
  disabled?: boolean;
}

export const CheckboxGroupControlledWrapper: FC<CheckboxGroupControlledWrapperProps> = (props) => {
  const renderCheckbox = (field?: ControllerRenderProps<FieldValues, string>) => {
    const onChange = (id: string, checked: boolean) => {
      let newValue = [];
      if (checked) {
        newValue = Array.from(new Set([...(field?.value ?? []), id]));
      } else {
        newValue = field?.value?.filter((v: string) => v !== id) || [];
      }
      field?.onChange({ target: { value: newValue } });
    };
    return (
      <FormGroup>
        {props.options?.map((option) => (
          <FormControlLabel
            key={option.value}
            control={
              <Checkbox
                data-testid="form-option-checkbox"
                checked={field?.value?.includes(option.value) ?? false}
                onChange={(_, checked) => onChange(option.value, checked)}
                inputProps={{ 'aria-label': option.label }}
                disabled={props.disabled}
              />
            }
            label={option.label}
          />
        ))}
      </FormGroup>
    );
  };
  return (
    <Controller
      name={props.name}
      control={props.control}
      render={(p) => {
        return renderCheckbox(p.field);
      }}
    />
  );
};
