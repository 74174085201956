export const FEATURES = {
  CONTRACT: 'contract',
  DRUG: 'drug',
  ERX: 'erx',
  FACILITY: 'facility',
  INVENTORY: 'inventory',
  INSURANCE: 'insurance',
  NOTE: 'note',
  PATIENT: 'patient',
  PHARMACY: 'pharmacy',
  PRESCRIBER: 'prescriber',
  PRESCRIPTION: 'prescription',
  SIG: 'sig',
  TAG: 'tag',
  USER: 'user',
  ROLE: 'role',
  PRICE_PLAN: 'price-plan',
  TRANSFER_OUT: 'transfer-out',
  RECYCLE_BIN: 'recycle-bin',
  LABEL_TEMPLATE: 'label-template',
  REPORT: 'report',
  CONFIGURATION: 'configuration',
  PAYER_SHEET: 'payer-sheet',
  POS: 'pos',
  DELIVERY: 'delivery',
  CATALOGUE_ITEM: 'pharmacy-catalogue-item',
  CATALOGUE_CATEGORY: 'master-categorization',
  TAX_PLAN: 'tax-plan',
  DISCOUNT_PLAN: 'discount-plan',
  TEXT_MESSAGE: 'text-message',
  ASSESSMENT_TEMPLATE: 'assessment-template',
  DAILY_CLOSE_OUT: 'daily-close-out'
} as const;
