import { theme } from '@linx-ui/shared/theming';

export const DRAWER_WIDTH = 256;
export const DRAWER_WITH_ACTION_WIDTH = 260;
export const DRAWER_WIDTH_XSM = '50%';
export const DRAWER_WIDTH_SM = '70%';
export const DRAWER_WIDTH_MD = '78.125%';
export const DRAWER_WIDTH_LG = '87.125%';
export const API_URL = process.env.API_URL;
export const pharmacyIdKey = 'Pharmacy-Id';
export const pharmacistIdKey = 'Pharmacist-Id';

export const DATE_FORMAT = 'YYYY-MM-DD';

export const ADDRESS_AUTOCOMPLETE = {
  region: process.env.ADDRESS_AUTOCOMPLETE_REGION,
  indexName: process.env.ADDRESS_AUTOCOMPLETE_INDEX_NAME,
  identityPoolId: process.env.ADDRESS_AUTOCOMPLETE_IDENTITY_POOL_ID,
  filterCountries: ['USA']
};

export const AUTH_CONFIG = {
  domain: process.env.AUTH_DOMAIN,
  clientId: process.env.AUTH_CLIENT_ID,
  aud: process.env.AUTH_AUD,
  connection: process.env.AUTH_CONNECTION,
  redirectURL: process.env.AUTH_REDIRECT_URL
};

export const TEN_MB = 10 * 1024 * 1024;
export const SPACE = ' ';

export const REACT_QUERY_INDEX_DB_KEY = 'reactQuery';
export const FULL_WIDTH_DRAWER = `calc(100% - ${theme.spacing(7)} + 1px)`;
export const DEFAULT_PAGE_LIMIT = 25;
export const CODA_CRM_BASE_LINK = 'https://coda.io/apis/v1/docs/zO1jTuS9Xw/hooks/automation/grid-auto';
export const CODA_CRM_DOC_ID = process.env.CODA_CRM_DOC_ID;
export const CODA_CRM_ACCESS_TOKEN = process.env.CODA_CRM_ACCESS_TOKEN;

export const OPTIO_DELIVERY_TRACK_BASE_LINK = 'https://app.optiodelivers.com/orderdata';
