import { NotificationsOff } from '@mui/icons-material';
import { Avatar, Skeleton, Typography } from '@mui/material';
import { memo, useMemo, type FC } from 'react';
import { ScrollableContent } from '@linx-ui/shared/components/styles';
import { NotificationItem } from '../notificationItem/NotificationItem';
import { getSegregatedNotifications } from '../utils/getSegregatedNotifications';
import {
  NoNewNotificationCnt,
  NotificationBucketCnt,
  NotificationBucketHeader,
  NotificationItemsCnt
} from './NotificationItems.styled';
import type { NotificationItemsProps } from './types';

export const NotificationItems: FC<NotificationItemsProps> = memo(
  ({ isLoading, isFetchingNextPage, onScroll, data }) => {
    const segregatedNotifications = useMemo(() => getSegregatedNotifications(data), [data]);
    const skeleton = useMemo(() => Array.from({ length: 3 }, (_, i) => <Skeleton key={i} height={50} />), []);

    if (isLoading) return <ScrollableContent hasBottomBar={false}>{skeleton}</ScrollableContent>;

    if (!data?.pages?.[0]?.items?.length)
      return (
        <ScrollableContent hasBottomBar={false}>
          <NoNewNotificationCnt>
            <Avatar>
              <NotificationsOff fontSize="large" />
            </Avatar>
            <Typography>No new notifications to report!</Typography>
          </NoNewNotificationCnt>
        </ScrollableContent>
      );

    return (
      <NotificationItemsCnt>
        <ScrollableContent data-testid="notifications-list" hasBottomBar={false} onScroll={onScroll}>
          {Object.entries(segregatedNotifications).map(([key, notifications]) =>
            notifications.length ? (
              <NotificationBucketCnt key={key}>
                <NotificationBucketHeader>{key}</NotificationBucketHeader>
                {notifications.map((notification) => (
                  <NotificationItem key={notification.id} notification={notification} />
                ))}
              </NotificationBucketCnt>
            ) : null
          )}
          {isFetchingNextPage && <>{skeleton}</>}
        </ScrollableContent>
      </NotificationItemsCnt>
    );
  }
);
NotificationItems.displayName = 'NotificationItems';
