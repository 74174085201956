import { Button, Step, StepContent, StepLabel, Stepper, Typography } from '@mui/material';
import { memo, useCallback } from 'react';
import { DRAWER_WIDTH_XSM, pharmacyIdKey, urls } from '@linx-ui/shared/constants';
import { useFormWrapperWithoutSteps, usePostMutation, useReactQueryList } from '@linx-ui/shared/hooks';
import { getDateDisplayValueLTLS, getFromLocalStorage } from '@linx-ui/shared/utils';
import { theme } from '@linx-ui/shared/theming';
import { useFeaturePermissions } from '@linx-ui/shared/core';
import { DrawerContainer } from '../../drawerContainer';
import {
  GridLayoutContainer,
  GridLayoutContainerWithSpacing2,
  GridLayoutItem,
  GridLayoutItemWithXs12sm12md12lg12xl12,
  GridLayoutItemWithXs12sm12md12lg6xl6
} from '../../gridLayout';
import { TextFieldWrapper } from '../../textFieldWrapper';
import { UserAvatar } from '../../userAvatar';
import { StyledSkeleton } from '../../styles';
import { type SendSMSDrawerProps, type TextMessage } from './types';
import { SendSMSDrawerContent } from './SendSMSDrawer.styled';
import { sendSMSLabels } from './labels';
import { sendSMSValidationRules } from './validationRules';

const _SendSMSDrawer = ({ title, open, toggle, defaultPhoneNumber, headerKeys, patientId }: SendSMSDrawerProps) => {
  const canSendSMS = useFeaturePermissions()('text-message')('send');
  const { control, getValidationError, getValues, isValid, reset } = useFormWrapperWithoutSteps();

  const { data, isLoading, refetch } = useReactQueryList<any>({
    url: urls.textMessages,
    params: { patientId },
    headerKeys: [pharmacyIdKey]
  });

  const { mutate: makeAPICall, isPending: inProgress } = usePostMutation({
    url: `${urls.textMessages}/send`,
    headerKeys,
    successMsg: () => 'SMS sent successfully',
    onSuccess: async () => {
      reset((values) => {
        return {
          ...values,
          message: ''
        };
      });
      await refetch();
    }
  });

  const onSend = useCallback(() => {
    makeAPICall({ ...getValues(), patientId, pharmacyId: getFromLocalStorage(pharmacyIdKey) });
  }, [getValues]);

  return (
    <DrawerContainer
      openDrawer={open}
      toggle={toggle}
      loader={inProgress}
      title={title}
      actionBtnsRight={[]}
      width={DRAWER_WIDTH_XSM}
    >
      <SendSMSDrawerContent data-testid="send-sms-drawer">
        <div>
          <GridLayoutContainerWithSpacing2>
            <GridLayoutItemWithXs12sm12md12lg6xl6>
              <TextFieldWrapper
                autoFocus={true}
                name="destinationNumber"
                control={control}
                defaultValue={defaultPhoneNumber}
                disabled={!canSendSMS}
                label={sendSMSLabels.phoneNumber}
                rules={sendSMSValidationRules.phoneNumber}
                error={getValidationError('destinationNumber')}
              />
            </GridLayoutItemWithXs12sm12md12lg6xl6>

            <GridLayoutItemWithXs12sm12md12lg12xl12>
              <TextFieldWrapper
                name="message"
                control={control}
                defaultValue=""
                disabled={!canSendSMS}
                label={sendSMSLabels.message}
                rules={sendSMSValidationRules.message}
                error={getValidationError('message')}
                multiline
                rows={2}
              />
            </GridLayoutItemWithXs12sm12md12lg12xl12>

            <GridLayoutItemWithXs12sm12md12lg12xl12>
              <div style={{ display: 'flex', justifyContent: 'end' }}>
                <Button
                  data-testid="send-btn"
                  variant="outlined"
                  size="large"
                  onClick={onSend}
                  disabled={!isValid || !canSendSMS}
                >
                  Send
                </Button>
              </div>
            </GridLayoutItemWithXs12sm12md12lg12xl12>
            <GridLayoutItemWithXs12sm12md12lg12xl12>
              <Typography variant="body2" color="secondary">
                Sent SMS
              </Typography>
            </GridLayoutItemWithXs12sm12md12lg12xl12>
          </GridLayoutContainerWithSpacing2>
        </div>

        <div style={{ flex: 2, height: `calc(100% - 75%)` }}>
          <GridLayoutContainer style={{ height: '100%', overflow: 'auto' }}>
            <GridLayoutItem xs={12}>
              {!isLoading ? (
                data.items.length ? (
                  <Stepper orientation="vertical">
                    {data.items.map((message: TextMessage) => {
                      return (
                        <Step active={true} key={message.id}>
                          <StepLabel
                            StepIconProps={{
                              icon: <UserAvatar userName={message.createdBy} />
                            }}
                          >
                            <GridLayoutContainer alignItems="center">
                              <GridLayoutItem item xs={4}>
                                <Typography variant="caption" color={theme.palette.text.secondary}>
                                  {getDateDisplayValueLTLS(message.createdTime)}
                                </Typography>
                              </GridLayoutItem>
                              <GridLayoutItem
                                item
                                xs={8}
                                data-testid="btn-edit-note"
                                display="flex"
                                justifyContent="flex-end"
                              ></GridLayoutItem>
                            </GridLayoutContainer>
                          </StepLabel>
                          <StepContent sx={{ wordBreak: 'break-word', whiteSpace: 'pre-wrap' }}>
                            {message.content}
                          </StepContent>
                        </Step>
                      );
                    })}
                  </Stepper>
                ) : (
                  'No text messages found'
                )
              ) : (
                <>
                  <StyledSkeleton />
                  <StyledSkeleton />
                </>
              )}
            </GridLayoutItem>
          </GridLayoutContainer>
        </div>
      </SendSMSDrawerContent>
    </DrawerContainer>
  );
};

export const SendSMSDrawer = memo(_SendSMSDrawer) as typeof _SendSMSDrawer;
