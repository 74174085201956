import { LocalPharmacyOutlined } from '@mui/icons-material';
import { Autocomplete, ListItemText, Skeleton } from '@mui/material';
import { memo, useEffect, useState } from 'react';
import { useGlobalContext } from '@linx-ui/shared/context';
import { getPharmacyId, savePharmacyId } from '@linx-ui/shared/utils';
import { type Pharmacy } from '@linx-ui/shared/types';
import { usePharmacies } from '../../hooks';
import { TextWithEllipis } from '../styles';
import { ButtonStyled, DropdownWrapper, TextFieldStyled } from './PharmacyDropdown.styled';
import { type Props } from './types';

export const PharmacyDropdown: React.FC<Props> = memo(({ handleClose }: Props) => {
  const globalPharmacyId = getPharmacyId();
  const { setPharmacyId } = useGlobalContext();
  const [defaultPharmacy, setDefaultPharmacy] = useState<Pharmacy | undefined>();
  const { pharmacies, isLoading: isLoadingPharmacies } = usePharmacies();

  useEffect(() => {
    pharmacies && setDefaultPharmacy(pharmacies.find((pharmacy: Pharmacy) => pharmacy.id === globalPharmacyId));
  }, [pharmacies, globalPharmacyId]);

  useEffect(() => {
    if (pharmacies?.length && !globalPharmacyId) {
      handleChange(pharmacies[0]);
    } else {
      setPharmacyId(globalPharmacyId!);
    }
  }, [pharmacies, globalPharmacyId]);

  const handleChange = (pharmacy: Pharmacy) => {
    setPharmacyId(pharmacy.id!);
    savePharmacyId(pharmacy.id!);
    window.dispatchEvent(new Event('pharmacy-change'));
  };

  const getOptionLabel = (option: Pharmacy) => option?.name ?? '';

  return (
    <DropdownWrapper>
      <ButtonStyled onClick={handleClose}>
        <LocalPharmacyOutlined />
      </ButtonStyled>
      {isLoadingPharmacies ? (
        <Skeleton width="100%" height="32px" />
      ) : (pharmacies?.length || 0) > 1 ? (
        <Autocomplete
          key={defaultPharmacy?.id}
          data-testid="pharmacy-dropdown"
          defaultValue={defaultPharmacy}
          disableClearable={true}
          getOptionLabel={getOptionLabel}
          options={pharmacies}
          onChange={(_, value) => handleChange(value)}
          renderInput={(params) => <TextFieldStyled {...params} variant="standard" placeholder="Select Pharmacy" />}
          renderOption={(props, pharmacy) => (
            <li {...props} key={pharmacy.id}>
              <ListItemText>{pharmacy.name}</ListItemText>
            </li>
          )}
        />
      ) : (
        <TextWithEllipis>{pharmacies[0]?.name}</TextWithEllipis>
      )}
    </DropdownWrapper>
  );
});
PharmacyDropdown.displayName = 'PharmacyDropdown';
