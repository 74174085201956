import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Checkbox, IconButton } from '@mui/material';
import { type ColumnDef } from '@tanstack/react-table';

const getDefaultColumn = (
  selectable: boolean,
  multiSelect: boolean,
  rowExpansion: boolean,
  showSelectAll: boolean
): ColumnDef<any> | undefined => {
  const onClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const noneEnabled = (): boolean => !selectable && !rowExpansion;

  const bothEnabled = (): boolean => selectable && rowExpansion;

  const getColSize = () => (bothEnabled() ? 80 : 46);

  const getExpandToggleIcon = (isExpanded: boolean) => {
    if (isExpanded) {
      return <KeyboardArrowUpIcon data-testid="row-collapse" />;
    } else {
      return <KeyboardArrowDownIcon data-testid="row-expand" />;
    }
  };

  if (noneEnabled()) {
    return undefined;
  }

  return {
    id: 'selection-col',
    enableResizing: false,
    enableHiding: false,
    size: getColSize(),
    header: ({ table }) => {
      if (!multiSelect || !showSelectAll) {
        return null;
      }

      const onClickAll = () => {
        const { rows } = table.getRowModel();
        const allSelected = table.getIsAllRowsSelected();
        rows.forEach((row) => {
          row.getCanSelect() && row.toggleSelected(!allSelected);
        });
      };

      return (
        <Checkbox
          className="select-all-checkbox"
          data-testid="select-all-checkbox"
          onClick={onClick}
          checked={table.getIsAllRowsSelected()}
          indeterminate={table.getIsSomeRowsSelected()}
          onClickCapture={onClickAll}
        />
      );
    },
    cell: ({ row }) => {
      return (
        <div onClick={onClick}>
          {selectable && (
            <Checkbox
              className="select-one-checkbox"
              data-testid="select-individual-checkbox"
              onClick={onClick}
              disabled={!row.getCanSelect()}
              indeterminate={row.getIsSomeSelected()}
              checked={row.getIsSelected() || row.getIsAllSubRowsSelected()}
              onChange={row.getToggleSelectedHandler()}
            />
          )}

          {row.getCanExpand() && (
            <IconButton
              data-testid="expand-row-icon"
              aria-label="expand row icon"
              size="small"
              onClick={row.getToggleExpandedHandler()}
            >
              {getExpandToggleIcon(row.getIsExpanded())}
            </IconButton>
          )}
        </div>
      );
    }
  };
};

export { getDefaultColumn };
