import { Button, CircularProgress, type ButtonProps } from '@mui/material';
import type { FC } from 'react';

export const LoadingButton: FC<ButtonProps & { loading?: boolean }> = ({
  children,
  loading,
  color = 'secondary',
  startIcon,
  disabled,
  ...props
}) => {
  return (
    <Button
      {...props}
      color={color}
      disabled={loading || disabled}
      startIcon={loading ? <CircularProgress color={color} size={20} /> : startIcon}
    >
      {children}
    </Button>
  );
};
