import { Badge, IconButton, Tab } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useBoolean, useToggle } from 'usehooks-ts';
import { DrawerContainer } from '@linx-ui/shared/components/drawerContainer';
import { useGlobalContext, ToggleContext } from '@linx-ui/shared/context';
import { isLinxAdmin } from '@linx-ui/shared/utils';
import { HeaderTabsStyled } from '../styles';
import { NotificationHeader } from './notificationHeader';
import { NotificationItems } from './notificationItems';
import { NotificationPreferences } from './notificationPreferences';
import { NotificationCnt, NotificationIcon } from './Notifications.styled';
import type { NotificationTabValues } from './types';
import { useNotifications } from './useNotifications';
import notificationAudio from './notification.mp3';

export const Notifications = () => {
  const { auth } = useGlobalContext();
  const audio = new Audio(notificationAudio);
  const [isOpen, toggleDrawer] = useToggle();
  const [tabValue, setTabValue] = useState<NotificationTabValues>('ALL');
  const { value: vibrate, toggle: toggleVibrate } = useBoolean();
  const { data, isLoading, refetch, isFetchingNextPage, hasNextPage, fetchNextPage } = useNotifications(tabValue);
  const unreadCount = data?.pages?.[0]?.metaData?.unreadCount || 0;

  const handleDrawerClose = useCallback(() => {
    toggleDrawer();
    setTabValue('ALL');
  }, []);

  const handleScroll = useCallback(
    async (e: WheelEvent) => {
      const target = e.target as HTMLElement;
      const isBottom = Math.floor(target.scrollHeight - target.scrollTop) === target.clientHeight;
      if (isBottom && hasNextPage && !isFetchingNextPage) await fetchNextPage();
    },
    [hasNextPage, isFetchingNextPage]
  );

  useEffect(() => {
    const channel = new BroadcastChannel('sw-messages');
    channel.addEventListener('message', async () => {
      await refetch();
      toggleVibrate();
      await audio.play();
      setTimeout(toggleVibrate, 500);
    });
  }, []);

  return (
    <ToggleContext.Provider value={[isOpen, toggleDrawer]}>
      <IconButton size="large" onClick={toggleDrawer} data-testid="notifications-badge">
        <Badge badgeContent={unreadCount} color="error">
          <NotificationIcon vibrate={vibrate} />
        </Badge>
      </IconButton>
      <DrawerContainer
        width="55%"
        title="Notifications"
        dataTestId="notification-drawer"
        openDrawer={isOpen}
        toggle={handleDrawerClose}
        headerComponents={isLinxAdmin(auth) ? undefined : [<NotificationPreferences key="preferences" />]}
      >
        <NotificationCnt>
          <div>
            <NotificationHeader unreadCount={unreadCount} />
            <HeaderTabsStyled value={tabValue} onChange={(_, value) => setTabValue(value)}>
              <Tab value="ALL" label="ALL" />
              <Tab value="PRESCRIPTION" label="RX" />
              <Tab value="SYSTEM" label="SYSTEM" />
              <Tab value="ARCHIVED" label="ARCHIVED" />
            </HeaderTabsStyled>
          </div>
          <NotificationItems
            data={data}
            isLoading={isLoading}
            isFetchingNextPage={isFetchingNextPage}
            onScroll={handleScroll}
          />
        </NotificationCnt>
      </DrawerContainer>
    </ToggleContext.Provider>
  );
};
