import Axios, { type AxiosError, type AxiosResponse } from 'axios';
import { API_URL } from '@linx-ui/shared/constants';

export const axios = Axios.create({
  baseURL: API_URL
});

const IGNORE_KEYS = ['path'];

export const removeEmptyValues = (obj: any): any => {
  if (obj === '' || obj === null || obj === undefined) {
    return null;
  } else if (Array.isArray(obj)) {
    const validArr = obj.map((value: any) => removeEmptyValues(value)).filter((v) => v !== null);
    return validArr.length ? validArr : null;
  } else if (typeof obj === 'object') {
    const validObj = Object.fromEntries(
      Object.entries(obj)
        .map(([key, value]) => [key, IGNORE_KEYS.includes(key) ? value : removeEmptyValues(value)])
        .filter(([_, v]) => v !== null)
    );
    return Object.keys(validObj).length ? validObj : null;
  } else {
    return typeof obj === 'string' ? obj.trim() : obj;
  }
};

export const successInterceptor = (response: AxiosResponse): AxiosResponse<any> => {
  return response.data;
};

export interface ErrorResponse {
  validationErrors: Record<string, string>;
  error: string;
}

export const errorInterceptor = (error: AxiosError<ErrorResponse>) => {
  return {
    error: true,
    message: error?.response?.data?.error,
    validationErrors: error?.response?.data?.validationErrors,
    meta: error?.response?.data,
    status: error?.response?.status
  };
};

axios.interceptors.request.use((config) => {
  return config;
});

axios.interceptors.request.use((config) => {
  config.data = removeEmptyValues(config.data);
  return config;
});
axios.interceptors.response.use(successInterceptor, errorInterceptor);
