import { Box, styled, Typography } from '@mui/material';

export const TableHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  paddingBottom: theme.spacing(4),
  flexWrap: 'wrap',
  gap: theme.spacing(2)
}));

export const TextStyled = styled(Typography, {
  shouldForwardProp: (props) => props !== 'open'
})<{ variant?: string }>(({ theme, variant }) => ({
  color: variant === 'subtitle1' ? theme.palette.text.secondary : 'inherit',
  letterSpacing: variant === 'subtitle1' ? '0.15px' : 'inherit',
  paddingLeft: 0
}));
