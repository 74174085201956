import { CustomDataGrid } from '@linx-ui/shared/components/customDataGrid';
import { type DataGridProps } from './types';

const TableWithData = (props: DataGridProps) => {
  const {
    name,
    testId,
    data = [],
    columns = [],
    enableSingleRowSelection,
    enableMultiRowSelection,
    showSelectAll,
    onRowSelection,
    loading,
    enableSearch,
    selectedRowIds,
    disableRowSelectionFn,
    tableToolbarComponents,
    serverSide,
    onSearch,
    onSort,
    onRowsScrollEnd,
    searchPlaceholder,
    enableSorting,
    disableQuerySearch,
    rowIdentifier
  } = props;

  let serverSideProps = {};
  if (serverSide) {
    serverSideProps = { onSearch, onSort, onRowsScrollEnd };
  }

  return (
    <CustomDataGrid
      name={name}
      testId={testId}
      data={data}
      columns={columns}
      rowIdentifier={rowIdentifier}
      enableSingleRowSelection={enableSingleRowSelection}
      enableMultiRowSelection={enableMultiRowSelection}
      showSelectAll={showSelectAll}
      onChangeRowSelection={onRowSelection}
      loading={loading}
      enableGlobalFilter={enableSearch}
      selectedRowIds={selectedRowIds}
      disableRowSelectionFn={disableRowSelectionFn}
      components={tableToolbarComponents}
      enableHideColumns
      searchPlaceholder={searchPlaceholder}
      {...serverSideProps}
      disableQuerySearch={disableQuerySearch}
      enableSorting={enableSorting}
    />
  );
};

export { TableWithData };
