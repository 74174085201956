import React from 'react';
import type * as ResizablePrimitive from 'react-resizable-panels';
import { DragIndicator } from '@mui/icons-material';
import { GripWrapper, HandleContainer, ResizablePanel, StyledPanelGroup } from './Resizable.styled';

const ResizableHandle = ({
  className,
  ...props
}: React.ComponentProps<typeof ResizablePrimitive.PanelResizeHandle> & {
  withHandle?: boolean;
}) => (
  <HandleContainer className={className} {...props}>
    <GripWrapper>
      <DragIndicator />
    </GripWrapper>
  </HandleContainer>
);

export { StyledPanelGroup as ResizablePanelGroup, ResizablePanel, ResizableHandle };
