import { BrokenImage } from '@mui/icons-material';
import { useMemo, useState, type MouseEventHandler } from 'react';
import { useBoolean } from 'usehooks-ts';
import { StyledImg, StyledInfoImg, StyledPopover } from './ImageWithHover.styled';
import { type ImageWithHoverProps } from './types';

const ImageWithHover = ({ src, action, noImagePlaceHolder = <BrokenImage />, ...rest }: ImageWithHoverProps) => {
  const { value: error, setTrue: setError } = useBoolean(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const onClose = {
    onClose: handlePopoverClose
  };

  const events:
    | {
        onMouseEnter?: MouseEventHandler<HTMLImageElement>;
        onMouseLeave?: MouseEventHandler<HTMLImageElement>;
        onClick?: MouseEventHandler<HTMLImageElement>;
      }
    | undefined = useMemo(() => {
    let event;
    if (action === 'click') {
      event = {
        onClick: handlePopoverOpen
      };
    } else if (action === 'hover') {
      event = {
        onMouseEnter: handlePopoverOpen,
        onMouseLeave: handlePopoverClose
      };
    } else {
      event = {};
    }
    return event;
  }, [action]);

  const id = open ? 'img-over-popover' : undefined;

  if (error) {
    return noImagePlaceHolder;
  }
  return (
    <>
      <StyledImg
        aria-owns={id}
        aria-haspopup="true"
        src={src}
        {...events}
        {...rest}
        alt="original image"
        onError={() => setError()}
      />
      <StyledPopover id={id} type={action} open={open} anchorEl={anchorEl} {...onClose}>
        <StyledInfoImg test-id="img-over-popover" src={src} alt="popover image" />
      </StyledPopover>
    </>
  );
};

export { ImageWithHover };
